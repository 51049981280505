import css from "styled-jsx/css";
import { LANGUAGE_PICKER_MENU } from "constants/z-index";
import Colors from "styles/colors";
import Dimensions from "components/core/language-picker/language-picker-dimensions";
import { DARK_WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import { MOBILE_QUERY_MAX_WIDTH_M } from "constants/vp-sizes";

const ITEM_WIDTH = `${Dimensions.LANGUAGE_ITEM_WIDTH}px`;
const ITEM_HEIGHT = `${Dimensions.LANGUAGE_ITEM_HEIGHT}px`;

export default css.global`
  .language-picker-component {
    position: relative;

    &:hover,
    &.is-open {
      .earth-icon {
        path {
          stroke: ${Colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
        }
      }

      .selected-language {
        .language-label {
          color: ${Colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
        }
      }

      .arrow-down-icon {
        path {
          fill: ${Colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
        }
      }
    }

    &.is-open {
      .selected-language {
        .arrow-down-icon {
          transform: translateY(1px) rotate(180deg);
        }
      }

      .language-picker-dialog-component-wrapper {
        opacity: 1;
        visibility: visible;
      }
    }

    .selected-language {
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;
      height: ${ITEM_HEIGHT};
      line-height: ${ITEM_HEIGHT};

      .earth-icon {
        path {
          transition: stroke 150ms ease;
          color: ${Colors["text-gray"]};
        }
      }

      .language-label {
        font-size: 0.875rem;
        margin: 0px 8px;
        max-width: ${ITEM_WIDTH};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: color 150ms ease;
        color: ${Colors["text-gray"]};
      }

      .arrow-down-icon {
        height: 10px;
        width: 10px;
        transform: translateY(1px);
        transition: transform 200ms ease;

        path {
          fill: ${Colors["text-gray"]};
          transition: fill 150ms ease;
        }
      }
    }

    .language-picker-dialog-component-wrapper {
      position: absolute;
      top: 40px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 200ms ease;
      z-index: ${LANGUAGE_PICKER_MENU};

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }

      &.top {
        top: -216px;
      }

      &.bottom {
        :global(.language-picker-dialog-component) {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
      }
    }
    @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
      .language-picker-dialog-component-wrapper {
        &.top {
          top: -200px;
        }
      }
    }
  }
`;
