import css from "styled-jsx/css";
import Dimensions from "components/core/language-picker/language-picker-dimensions";
import { MOBILE_QUERY_MAX_WIDTH_M } from "/constants/vp-sizes";

const DIALOG_PADDING = `${Dimensions.DIALOG_PADDING}px`;
const ITEM_MARGIN = `${Dimensions.LANGUAGE_ITEM_MARGIN}px`;

export default css.global`
  .language-picker-dialog-component {
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    padding: ${DIALOG_PADDING};
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .language-picker-item-component-wrapper {
      margin: ${ITEM_MARGIN};
    }

    @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
      flex-direction: row;
      overflow: auto;
      width: 100% !important;
      height: 186px !important;
    }
  }
`;
