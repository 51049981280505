import css from "styled-jsx/css";
import Colors from "styles/colors";
import Dimensions from "components/core/language-picker/language-picker-dimensions";
import { BACKGROUND_GRAY_COLOR_NAME, DARK_WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";

const ITEM_HEIGHT = `${Dimensions.LANGUAGE_ITEM_HEIGHT}px`;
const ITEM_WIDTH = `${Dimensions.LANGUAGE_ITEM_WIDTH}px`;

export default css.global`
  .language-picker-item-component {
    height: ${ITEM_HEIGHT};
    width: ${ITEM_WIDTH};
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 0px 8px;
    cursor: pointer;
    user-select: none;
    transition: background-color 200ms ease;

    &:hover {
      background-color: ${Colors.riverstone};
    }

    &.language-selected {
      background-color: ${Colors[BACKGROUND_GRAY_COLOR_NAME]};
      .language-picker-item-label {
        color: ${Colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
      }
    }

    .language-picker-item-label {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: ${ITEM_HEIGHT};
      font-size: 0.8125rem;
      color: ${Colors["text-gray"]};
    }
  }
`;
