import { PureComponent } from "react";
import componentStyles from "./language-picker-item-component.scss";
import classnames from "classnames";
import { generateOnKeyDownHandler } from "/client/services/accessibility/accessibility-service";

export default class LanguagePickerItemComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.onKeyDown = generateOnKeyDownHandler(this.onClick);
  }

  onClick = () => {
    const { id, onClick } = this.props;

    onClick(id);
  };

  render() {
    const { label, isSelected } = this.props;

    return (
      <div
        className={classnames("language-picker-item-component", { "language-selected": isSelected })}
        onClick={this.onClick}
        tabIndex={0}
        onKeyDown={this.onKeyDown}
        role="option"
        aria-selected={isSelected}
      >
        <div className="language-picker-item-label">{label}</div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
