import { PureComponent } from "react";
import classnames from "classnames";
import componentStyles from "./language-picker-component.scss";
import { withGenericConfig } from "/contexts/generic-config-context";
import {LANGUAGES, MAIN_LANGUAGES_VALUES} from "./language-picker-service";
import { ENGLISH_LOCALE_ID } from "constants/localization";
import EarthIcon from "static/svg/earth.svg";
import ArrowDown from "static/svg/arrow-down.svg";
import LanguagePickerDialogComponent from "./language-picker-dialog/language-picker-dialog-component";
import { generateOnKeyDownHandler } from "/client/services/accessibility/accessibility-service";
import { trackEvent } from "services/bigbrain-service";
import { LANGUAGE_PICKER_OPENED } from "constants/bigbrain-event-types";
import * as Sentry from '@sentry/nextjs';

const MIN_WINDOW_DISTANCE = 200;
const LEFT_DIRECTION = "left";
const RIGHT_DIRECTION = "right";

class LanguagePickerComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDialogOpen: false,
      selectedLanguageId: props.userSelectedLanguage || props.localeId || ENGLISH_LOCALE_ID,
      horizontalDirection: LEFT_DIRECTION
    };

    this.languages = props.showMainLanguagesOnly ? MAIN_LANGUAGES_VALUES : Object.values(LANGUAGES);

    this.getHorizontalDirection = this.getHorizontalDirection.bind(this);
    this.onKeyDown = generateOnKeyDownHandler(this.toggleDialog);
  }

  componentDidMount() {
    window.addEventListener("resize", this.getHorizontalDirection);
    this.getHorizontalDirection();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.getHorizontalDirection);
  }

  openDialog = () => {
    const { selectedLanguageId } = this.state;
    trackEvent(LANGUAGE_PICKER_OPENED, { info1: selectedLanguageId });
    this.setState({
      isDialogOpen: true
    });
  };

  closeDialog = () => {
    this.setState({
      isDialogOpen: false
    });
  };

  toggleDialog = () => {
    const { isDialogOpen } = this.state;

    if (isDialogOpen) {
      this.closeDialog();
    } else {
      this.openDialog();
    }
  };

  getHorizontalDirection() {
    if (!this.selector) return;
    let horizontalDirection = LEFT_DIRECTION;
    const horizontalPosition = this.selector.getBoundingClientRect().right;
    if ((window.innerWidth || document.documentElement.clientWidth) - horizontalPosition < MIN_WINDOW_DISTANCE) {
      horizontalDirection = RIGHT_DIRECTION;
    }

    this.setState({ horizontalDirection });
  }

  getShortLabel() {
    const { selectedLanguageId } = this.state;
    const selectedLanguage = LANGUAGES[selectedLanguageId];
    const { shortLabel } = selectedLanguage;

    return shortLabel.toUpperCase();
  }

  render() {
    const { isDialogOpen, selectedLanguageId } = this.state;
    const { dialogDirection, useShortLabels, translate, showLabel } = this.props;
    let selectedLanguage = LANGUAGES[selectedLanguageId];
    if (!selectedLanguage) {
      Sentry.captureMessage(
        `Selected language Id is: ${selectedLanguageId}, and it is not supported in the language picker.`
      );
      selectedLanguage = LANGUAGES[ENGLISH_LOCALE_ID];
    }
    const { label } = selectedLanguage;
    const { horizontalDirection } = this.state;

    const labelToShow = useShortLabels ? this.getShortLabel() : label;

    return (
      <div
        className={classnames("language-picker-component", { "is-open": isDialogOpen })}
        ref={(el) => {
          this.selector = el;
        }}
        tabIndex={0}
        aria-haspopup="true"
        aria-expanded={isDialogOpen}
        aria-label={translate("languagePicker.chooseLanguageLabel")}
        onKeyDown={this.onKeyDown}
        role="combobox"
      >
        <div className="selected-language ignore-clickoutside" onClick={this.toggleDialog}>
          <EarthIcon className="earth-icon" />
          {showLabel && (
            <div className="language-label">{labelToShow}</div>
          )}
          <ArrowDown className="arrow-down-icon" />
        </div>

        <div className={classnames("language-picker-dialog-component-wrapper", dialogDirection, horizontalDirection)}>
          <LanguagePickerDialogComponent
            languages={this.languages}
            selectedLanguageId={selectedLanguageId}
            closeDialog={this.closeDialog}
            outsideClickIgnoreClass="ignore-clickoutside"
          />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

LanguagePickerComponent.defaultProps = {
  localeId: ENGLISH_LOCALE_ID,
  useShortLabels: false,
  showLabel: true
};

export default withGenericConfig(LanguagePickerComponent);
