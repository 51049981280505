import { PureComponent } from "react";
import onClickOutside from "react-onclickoutside";
import componentStyles from "./language-picker-dialog-component.scss";
import Dimensions from "../language-picker-dimensions";
import LanguagePickerItemComponent from "./language-picker-item/language-picker-item-component";
import { trackEvent } from "/services/bigbrain-service";
import { USER_SELECTED_LOCALE_ID_COOKIE } from "constants/localization";
import { LANGUAGE_PICKER_LANG_CHANGED } from "/constants/bigbrain-event-types";
import { setCookie } from "/services/cookies-service";

class LanguagePickerDialogComponent extends PureComponent {
  constructor(props) {
    super(props);
  }

  handleClickOutside() {
    const { closeDialog } = this.props;

    closeDialog();
  }

  getComponentStyle() {
    return {
      height: this.getComponentHeight(),
      width: this.getComponentWidth()
    };
  }

  getComponentHeight() {
    const { languages, maxElementsInColumn } = this.props;

    const totalPadding = Dimensions.DIALOG_PADDING * 2;
    const numberOfElementsInColumn = Math.min(maxElementsInColumn, languages.length);
    const totalElementsHeight = Dimensions.LANGUAGE_ITEM_HEIGHT * numberOfElementsInColumn;
    const totaElementsPadding = Dimensions.LANGUAGE_ITEM_MARGIN * numberOfElementsInColumn * 2;

    return totalPadding + totalElementsHeight + totaElementsPadding;
  }

  getComponentWidth() {
    const { languages, maxElementsInColumn } = this.props;

    const totalPadding = Dimensions.DIALOG_PADDING * 2;
    const numberOfElementsInRow = Math.ceil(languages.length / maxElementsInColumn);
    const totalElelementsWidth = Dimensions.LANGUAGE_ITEM_WIDTH * numberOfElementsInRow;
    const totaElementsPadding = Dimensions.LANGUAGE_ITEM_MARGIN * numberOfElementsInRow * 2;

    return totalPadding + totalElelementsWidth + totaElementsPadding;
  }

  onChangeLanguage = (languageId) => {
    const { selectedLanguageId, closeDialog } = this.props;

    closeDialog();

    if (selectedLanguageId !== languageId) {
      trackEvent(LANGUAGE_PICKER_LANG_CHANGED, { info1: languageId, info2: selectedLanguageId });
      setCookie(USER_SELECTED_LOCALE_ID_COOKIE, languageId);
      window.location.reload();
    }
  };

  renderLanguageItems() {
    const { languages, selectedLanguageId } = this.props;

    return languages.map((language, index) => {
      return (
        <div key={index} className="language-picker-item-component-wrapper">
          <LanguagePickerItemComponent
            {...language}
            isSelected={selectedLanguageId === language.id}
            onClick={this.onChangeLanguage}
          />
        </div>
      );
    });
  }

  render() {
    const style = this.getComponentStyle();

    return (
      <div className="language-picker-dialog-component" style={style} role="listbox">
        {this.renderLanguageItems()}

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

LanguagePickerDialogComponent.defaultProps = {
  maxElementsInColumn: 5
};

export default onClickOutside(LanguagePickerDialogComponent);
